
* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: "Courier New", Courier, monospace;
  background: linear-gradient(89deg, #FF5EDF 0%, #04C8DE 100%); /* w3c */
  }


button{
  cursor: pointer;
}
footer {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  color: rgb(0, 0, 0);
  font-size: 1em;
  width: 100%;
  margin-top: auto;
  font-family: Georgia, 'Times New Roman', Times, serif;


}